define("discourse/plugins/flagged-posts-config/initializers/flagged-posts-config", ["exports", "discourse/lib/plugin-api", "discourse/widgets/widget", "discourse/widgets/decorator-helper", "I18n", "discourse/widgets/post-cooked", "virtual-dom"], function (_exports, _pluginApi, _widget, _decoratorHelper, _I18n, _postCooked, _virtualDom) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const pluginId = "flagged-posts-config";
  function init(api) {
    api.reopenWidget("post-contents", {
      html(attrs, state) {
        let result = [new _postCooked.default(attrs, new _decoratorHelper.default(this), this.currentUser)];
        if (attrs.requestedGroupName) {
          result.push(this.attach("post-group-request", attrs));
        }
        result = result.concat((0, _widget.applyDecorators)(this, "after-cooked", attrs, state));
        if (attrs.cooked_hidden && (this.siteSettings.can_see_flagged_posts_groups?.split('|').includes('0') || this.currentUser?.can_see_hidden_post || attrs.user_id === this.currentUser?.id)) {
          result.push(this.attach("expand-hidden", attrs));
        }
        if (!state.expandedFirstPost && attrs.expandablePost) {
          result.push(this.attach("expand-post-button", attrs));
        }
        const extraState = {
          state: {
            repliesShown: !!state.repliesBelow.length,
            filteredRepliesShown: state.filteredRepliesShown
          }
        };
        result.push(this.attach("post-menu", attrs, extraState));
        const repliesBelow = state.repliesBelow;
        if (repliesBelow.length) {
          result.push((0, _virtualDom.h)(`section.embedded-posts.bottom#embedded-posts__bottom--${this.attrs.post_number}`, [repliesBelow.map(p => {
            return this.attach("embedded-post", p, {
              model: p.asPost,
              state: {
                role: "region",
                "aria-label": _I18n.default.t("post.sr_embedded_reply_description", {
                  post_number: attrs.post_number,
                  username: p.username
                })
              }
            });
          }), this.attach("button", {
            title: "post.collapse",
            icon: "chevron-up",
            action: "toggleRepliesBelow",
            actionParam: "true",
            className: "btn collapse-up",
            translatedAriaLabel: _I18n.default.t("post.sr_collapse_replies")
          })]));
        }
        return result;
      }
    });
  }
  var _default = _exports.default = {
    name: pluginId,
    initialize(container) {
      if (!container.lookup("site-settings:main").flagged_posts_config_enabled) {
        return;
      }
      (0, _pluginApi.withPluginApi)("1.6.0", init);
    }
  };
});